/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType,
  PayHubAdminPanelDomainEnumsParameterDataType,
  PayHubCashierContractsInitParamsResponse,
  PayHubCashierContractsLostProfitMerchantBonus,
  PayHubCashierContractsLostProfitMerchantBonusTier,
  PayHubCashierContractsPaymentGroupInfo as GroupInfo,
  PayHubCashierContractsPaymentsInitPaymentGroupsCurrencyResponse as Currency,
  PayHubCashierContractsPaymentsInitPaymentGroupsResponse as InitSuccess,
  LostProfitInfo,
  PayHubWidgetContractsPayoutsSelectPayoutGroupResponse,
  PaymentProcessMethodInfo,
  BonusInfoResponse,
} from 'store/models';
import { DEFAULT_EMPTY_CURRENCY_ALPHA3, noMethodsError } from 'store/constants';
import {
  transformContentUrl,
  getViewData,
  parseQuery,
  stringToInt,
} from 'utils';
import {
  formNotificationData,
  getCurrency,
  getEnabled,
  getMethodsTypes,
  getMinLimit,
  sortByArrangement,
  getIsLimitReached,
  UIPaymentGroups,
  GeneralInfo,
  UIPayment,
  getMaxAmount, ViewDataType,
} from './commonPayments';
import { NAMESPACES } from '../../services/constants';

export const lostProfitLocizeKeys = {
  SINGLE_TIER: {
    ONLY_PERCENTS: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.RIBBON_FIXED_PERCENT`,
    ONLY_ABSOLUTE: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.RIBBON_FIXED_VALUE`,
    PERCENTS_AND_ABSOLUTE: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.RIBBON_PERCENT_VALUE`,
  },
  MULTIPLE_TIERS: {
    MAX_PERCENTS: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.RIBBON_MAX_PERCENT`,
    MAX_ABSOLUTE: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.RIBBON_MAX_VALUE`,
    MAX_PERCENTS_AND_MAX_ABSOLUTE: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.RIBBON_MAX_PERCENT_VALUE`,
  },
  LOST_PROFIT_METHODS: {
    HEADER: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.HEADER`,
    INFO: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.INFO`,
    AMT: `${NAMESPACES.PW_KEYS}:CASHIER.PH2.LOST_PROFIT_MERCHANT_BONUS.AMT`,
  },
};

export const calculateBiggest = (tiers: PayHubCashierContractsLostProfitMerchantBonusTier[]) => {
  let biggestPercentValue = 0;
  let biggestAbsoluteValue = 0;

  tiers.forEach((tier) => {
    if (tier.percent_value && tier.percent_value > biggestPercentValue) {
      biggestPercentValue = tier.percent_value;
    }
    if (tier.absolute_value && tier.absolute_value > biggestAbsoluteValue) {
      biggestAbsoluteValue = tier.absolute_value;
    }
  });

  return {
    percent_value: biggestPercentValue,
    absolute_value: biggestAbsoluteValue,
  };
};

export const generateLabelForLostProfit = (
  method: PayHubCashierContractsLostProfitMerchantBonus,
  currencyName: string,
): LostProfitInfo => {
  const isSingleTier = method.tiers.length === 1;
  const isMultipleTiers = method.tiers.length > 1;
  const isOnlyPercentProfit = method.tiers.every((item) => item.absolute_value === 0);
  const isOnlyAbsoluteValue = method.tiers.every((item) => item.percent_value === 0);
  const isZeroValue = method.tiers.every((item) => item.percent_value === 0 && item.absolute_value === 0);
  const labelProps = {
    ...calculateBiggest(method.tiers),
    currency: currencyName,
  };

  if (isSingleTier && !isZeroValue) {
    if (isOnlyPercentProfit) {
      return {
        text: lostProfitLocizeKeys.SINGLE_TIER.ONLY_PERCENTS,
        props: labelProps,
      };
    }

    if (isOnlyAbsoluteValue) {
      return {
        text: lostProfitLocizeKeys.SINGLE_TIER.ONLY_ABSOLUTE,
        props: labelProps,
      };
    }

    return {
      text: lostProfitLocizeKeys.SINGLE_TIER.PERCENTS_AND_ABSOLUTE,
      props: labelProps,
    };
  }

  if (isMultipleTiers && !isZeroValue) {
    if (isOnlyPercentProfit) {
      return {
        text: lostProfitLocizeKeys.MULTIPLE_TIERS.MAX_PERCENTS,
        props: labelProps,
      };
    }

    if (isOnlyAbsoluteValue) {
      return {
        text: lostProfitLocizeKeys.MULTIPLE_TIERS.MAX_ABSOLUTE,
        props: labelProps,
      };
    }
    return {
      text: lostProfitLocizeKeys.MULTIPLE_TIERS.MAX_PERCENTS_AND_MAX_ABSOLUTE,
      props: labelProps,
    };
  }
  return {
    text: '',
    props: {
      percent_value: 0,
      absolute_value: 0,
      currency: '',
    },
  };
};

export type LostProfitCompareResult = {
  isLostProfit: boolean;
  label: LostProfitInfo | null;
};

export const getLostProfitCompareResult = (
  currentMethod: GroupInfo,
  lostProfitRawData?: PayHubCashierContractsLostProfitMerchantBonus[] | null,
): LostProfitCompareResult => {
  const defaultReturn = {
    isLostProfit: false,
    label: null,
  };
  try {
    if (lostProfitRawData) {
      return lostProfitRawData.reduce((
        acc: LostProfitCompareResult,
        currItem: PayHubCashierContractsLostProfitMerchantBonus,
      ) => {
        if (currItem.pay_group.toLowerCase() === currentMethod.short_name!.toLowerCase()) {
          const [currency] = currentMethod.currencies as Currency[];
          const currencyName = currency.currency!.alpha3 as string;
          return {
            isLostProfit: true,
            label: generateLabelForLostProfit(currItem, currencyName),
          };
        }
        return acc;
      }, defaultReturn);
    }
  } catch {
  }

  return defaultReturn;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const formDepositMethod = (generalInfo: GeneralInfo) => (pmMethod: GroupInfo): UIPaymentGroups => {
  const {
    isLimitReached,
    generalViewData,
    lost_profit_merchant_bonus,
    userBonuses,
  } = generalInfo;
  const currentCurrency = getCurrency(pmMethod);
  const min = getMinLimit(currentCurrency);
  const max = getMaxAmount(currentCurrency);
  const lostProfitCompareResult = getLostProfitCompareResult(
    pmMethod,
    lost_profit_merchant_bonus,
  );

  // now we use only first bonus in array user_bonuses BILL-16033
  const bonusesPayGroups = (userBonuses && userBonuses[0].bonusPayGroupsIds) || [];

  const infoLink = pmMethod.info;
  const methodId = pmMethod.id || 0;
  const isIncludeBonus = Boolean(bonusesPayGroups.includes(methodId));

  return {
    arrangement: pmMethod.arrangement || 0,
    id: pmMethod.id || 0,
    name: pmMethod.short_name?.trim() || '',
    displayName: pmMethod.public_name || '',
    isPopular: Boolean(pmMethod.popular),
    logoUri: pmMethod.logo || '',
    nativePayType: pmMethod.native_pay_type || '',
    paymentType: '',
    requiredFields: [],
    currencyId: currentCurrency.currency?.currency_id || 0,
    currencyAlpha3: currentCurrency.currency?.alpha3 || '',
    descriptionUrl: infoLink
      ? transformContentUrl(infoLink)
      : '',
    limits: {
      min,
      max,
    },
    amountRegExpFe: '',
    viewData: [],
    payGroupActionType: pmMethod.action_type || '',
    fee: currentCurrency.fee || '',
    merchantUserFee: pmMethod.merchant_user_fee,
    processingTimeTranslationKey: currentCurrency.processing_time_translation_key || undefined,
    showProcessingTime: currentCurrency.show_processing_time || false,
    isSelfTarget: pmMethod.info_in_iframe || false,
    enabled: getEnabled(isLimitReached, min, pmMethod, generalViewData),
    restrictedUntil: pmMethod?.restricted_until,
    pay_method_type: pmMethod.pay_method_type || '',
    last_used: pmMethod.last_used || false,
    autoSubmit: pmMethod.auto_submit || false,
    isCheckout: false,
    preSelectedMethod: pmMethod.allow_pre_selected_method || false,
    isLostProfit: lostProfitCompareResult.isLostProfit || false,
    lostProfitInfo: lostProfitCompareResult.label || null,
    isLoading: true,
    showInCarousel: pmMethod.show_in_carousel || false,
    isIncludeBonus,
  };
};

const shouldBeRedirectedToMethod = (lastUsedMethod?: UIPaymentGroups) => lastUsedMethod?.pay_method_type === 'Credit and Debit Cards'
&& lastUsedMethod.payGroupActionType === 'activity'
&& lastUsedMethod.enabled
&& lastUsedMethod.preSelectedMethod;

export const getMethodForAutoRedirect = (
  props: {
      methods: UIPaymentGroups[];
      initCarouselMethods: UIPaymentGroups[];
      isInitialCarouselLoad?: boolean;
      payGroupId: number;
      skip: boolean;
    },
): UIPaymentGroups | undefined => {
  const {
    methods = [],
    initCarouselMethods = [],
    isInitialCarouselLoad,
    payGroupId,
    skip,
  } = props;
  const [firstMethod] = methods;

  if (!firstMethod) {
    return undefined;
  }

  if (skip) {
    return undefined;
  }

  if (methods.length === 1 && firstMethod.enabled) {
    return firstMethod;
  }

  if (payGroupId) {
    const method = methods.find((m) => m.id === payGroupId);
    if (method) {
      return method;
    }
  }

  const lastUsedMethod = methods.find((m) => m.last_used);

  if (shouldBeRedirectedToMethod(lastUsedMethod)) {
    return lastUsedMethod;
  }

  const { pay_group = '' } = parseQuery(window.location.search);
  const methodFromQuery = methods.find(({ id }) => id === stringToInt(pay_group as string));

  if (methodFromQuery && methodFromQuery.enabled) {
    return methodFromQuery;
  }

  if (initCarouselMethods.length > 1 && !isInitialCarouselLoad) {
    return initCarouselMethods[0];
  }

  return undefined;
};

const formatUserBonuses = (methods: GroupInfo[], bonuses?: BonusInfoResponse[]) => {
  if (!bonuses) return null;

  return bonuses.map((bonus) => {
    const bonusPayGroups = bonus.pay_groups || [];
    const bonusPayGroupsIds = methods.filter((item) => bonusPayGroups.includes(item.short_name || '')).map((item) => item.id || 0);
    return {
      ...bonus,
      bonusPayGroupsIds,
    };
  });
};

export const formDepositGroups = (payload: InitSuccess): UIPayment => {
  const {
    view_data,
    payment_groups = null,
    cashier_settings = {},
    cashier_features = [],
    lost_profit_merchant_bonus = [],
    withdrawal_reverse,
    tax,
    user_bonuses,
    merchant_url,
    merchant_success_url,
    merchant_fail_url,
    merchant_processing_url,
    merchant_redirect_type,
    // NOTICE: user_balance always return, it is not nullable
    user_balance = 0,
  } = payload;

  const groups = Array.isArray(payment_groups) ? payment_groups : [];
  const preparedViewData = (groups?.length ? view_data : [
    noMethodsError,
    ...view_data || [],
  ]) as ViewDataType;
  const isLimitReached = getIsLimitReached(preparedViewData);
  const formattedBonuses = formatUserBonuses(groups, user_bonuses);

  const generalSettings: GeneralInfo = {
    isLimitReached,
    generalViewData: preparedViewData,
    lost_profit_merchant_bonus,
    userBonuses: formattedBonuses,
  };
  const methods = groups.sort(sortByArrangement).map(formDepositMethod(generalSettings));
  const initCarouselMethods = methods.filter(({
    showInCarousel,
    enabled,
  }) => showInCarousel && enabled);
  const currencyId = (methods && methods[0]?.currencyId) || null;
  const currencyAlpha3 = (methods && methods[0]?.currencyAlpha3) || null;
  const methodsTypes = getMethodsTypes(methods);

  return {
    tax,
    methodsTypes,
    isReady: true,
    canAutoRedirect: true,
    isRequestPending: false,
    submitPaymentReady: false,
    currencyId,
    currencyAlpha3,
    widgetSettings: cashier_settings,
    growthbookFeatures: cashier_features,
    methods,
    initCarouselMethods,
    // lostProfitMethods: extendedLostProfitMerchantBonus,
    viewData: formNotificationData({
      viewData: preparedViewData,
      withdrawalReverse: withdrawal_reverse,
      isGeneral: true,
    }),
    withdrawalReverse: withdrawal_reverse,
    merchantUrl: merchant_url,
    merchantSuccessUrl: merchant_success_url,
    merchantFailUrl: merchant_fail_url,
    merchantProcessingUrl: merchant_processing_url,
    merchantRedirectType: merchant_redirect_type,
    userBonuses: formattedBonuses,
    userBalance: user_balance,
  };
};

export const formDepositRequiredFields = (
  parameters: PayHubCashierContractsInitParamsResponse[] | null | undefined,
// eslint-disable-next-line sonarjs/cognitive-complexity
): Parameter[] | [] => {
  if (!parameters || parameters.length === 0) {
    return [];
  }

  return parameters.map(({
    name,
    type,
    extended_type,
    data_type,
    regexp,
    label,
    place_holder,
    place_holder_empty_list,
    place_holder_empty_list_error,
    is_required,
    is_otp_requisites,
    options,
    user_requisite,
    validation_msg_key,
    is_read_only,
  }: PayHubCashierContractsInitParamsResponse): Parameter => {
    const formattedOptions = options && options.length ? options.map((option) => ({
      ...option,
      selected: option.is_selected || false,
      name: option.label || '',
      value: option.value || '',
    })) : undefined;

    return {
      name: name || '',
      key: name || JSON.stringify(label),
      type: type || PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType.Input,
      extendedType: extended_type,
      dataType: data_type || PayHubAdminPanelDomainEnumsParameterDataType.String,
      regexp: regexp || undefined,
      label,
      placeHolder: place_holder,
      placeHolderEmptyList: place_holder_empty_list,
      placeHolderEmptyListError: place_holder_empty_list_error,
      isRequired: is_required,
      isOtpRequisites: is_otp_requisites,
      options: formattedOptions,
      userRequisite: user_requisite || null,
      validationMsgKey: validation_msg_key,
      isDisabled: is_read_only,
    };
  });
};

export const formDepositMethodInfo = (
  response: PayHubWidgetContractsPayoutsSelectPayoutGroupResponse,
  requiredFieldsFormatter: (params: PayHubCashierContractsInitParamsResponse[] | null | undefined) => Parameter[],
  payGroupId: number,
): PaymentProcessMethodInfo => {
  const {
    currency = {},
    limits = {},
    amount_blocks,
    parameters,
    view_data,
    processing_currency,
    show_processing_currency,
    amount_reg_exp_fe,
    auto_submit,
    preset_behavior,
    preset_amount,
    preset_currency,
    allow_preset_amount,
    merchant_user_fee,
    processing_currency_rate,
    processing_time_translation_key,
    workflow_type,
    info,
  } = response;
  const {
    currency_id,
    alpha3,
  } = currency;
  const {
    min_amount,
    max_amount,
    max_recurring_amount,
  } = limits;

  const limitsObject = {
    min: min_amount || 0,
    max: max_amount || undefined,
    maxRecurrentAmount: max_recurring_amount || undefined,
  };
  const requiredFields = requiredFieldsFormatter(parameters);
  const { additionalViewData, viewData } = getViewData(view_data);

  return {
    showProcessingTime: show_processing_currency as boolean,
    currencyAlpha3: alpha3 || DEFAULT_EMPTY_CURRENCY_ALPHA3,
    limits: limitsObject,
    currencyId: currency_id || 0,
    requiredFields,
    additionalViewData,
    viewData: formNotificationData({
      viewData,
      payGroupId,
    }),
    isCheckout: workflow_type?.toLowerCase() === 'host-to-host',
    processingCurrency: processing_currency,
    amountBlocks: amount_blocks,
    amountRegExpFe: amount_reg_exp_fe || undefined,
    autoSubmit: auto_submit || preset_behavior?.toLowerCase() === 'autosubmit',
    presetBehavior: preset_behavior,
    presetAmount: preset_amount,
    presetCurrency: preset_currency,
    allowPresetAmount: allow_preset_amount,
    merchantUserFee: merchant_user_fee,
    processingCurrencyRate: processing_currency_rate || undefined,
    info: info || undefined,
    processingTimeTranslationKey: processing_time_translation_key
      ? `${NAMESPACES.PAYGOUPS_AND_PROCESSING_TIME}:${processing_time_translation_key}`
      : undefined,
  };
};
