/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Icon, Toast } from '@modulor/react';
import {
  useLazySubmitWithdrawalQuery,
  useGetWithdrawalMethodInfoQuery, CustomServerError, useLazyGetWithdrawalMethodsQuery,
} from 'store/services/withdrawalAPI';
import { FlowTypes } from 'store/constants';
import {
  methodNameSelector, withdrawalSelectedCardSelector,
  disableAccountVerification,
  currentWithdrawalMethodCurrencySelector,
} from 'store/slices/withdrawal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  PayoutProcessMethodInfo,
  Notifications,
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
} from 'store/models';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { prepareParametersForSubmit, getErrorMessage, setNullToNotRequiredFields } from 'utils';
import {
  useFormContainerHook,
  useGetPayGroupId,
  useNavigateWithSearch,
  useFrameChangeHeight,
  useAbortSubmitControllerRef,
  useReinit,
  useSetInitDataToStore,
  useSetGroupDataAttributes,
} from 'Modulor/hooks';
import { showErrorPopUp } from 'store/slices/errorPopUp';
import { setRequisiteId } from 'store/slices/accountManagement';
import { useLazyCreateRequisiteQuery } from 'store/services/accountManagementAPI';
import {
  Clickstream,
  deleteClickStreamPayGroupCashierContext,
  getClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
  setClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { hasValidationError } from 'utils/form';
import { useTranslation } from 'react-i18next';
import {
  prepareFieldsDataForSubmit,
  isCardPanSelectTypeField,
  handleRedirectForVerification,
  handleRedirectToOTP,
} from '../OTP/utils';
import { SubmitFormWrapper } from '../components/SubmitForm/SubmitFormWrapper';
import ModulorLoader from '../components/ModulorLoader';
import { WithdrawalForm } from './WithdrawalForm';
import { SubmitHandler } from '../SubmitStrategy/SubmitHandler';
import { useSetNotificationsHook } from '../components/Notifications/notificationsHooks';
import { isOTPEnabledSelector } from '../../store/slices/otp';
import { getSubmitStrategy } from '../Deposit/utils';
import { amountSelector, setHeadersNavIconRedirectPath, setSessionExpired } from '../../store/slices/global';
import { UIPayment, UIPayout } from '../../store/formatters';
import { isCarouselViewSelector } from '../../store/slices/carousel';
import { setDefaultPaySessionState } from '../../store/slices/paySessionState';
import { Routes } from '../router';

export const WithdrawalMethod = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithSearch();
  const methodName = useAppSelector(methodNameSelector);
  const isOTPEnabled = useAppSelector(isOTPEnabledSelector);
  const payGroupId = useGetPayGroupId();
  const currency = useAppSelector(currentWithdrawalMethodCurrencySelector);
  const selectedCard = useAppSelector(withdrawalSelectedCardSelector);
  const amount = parseFloat(useAppSelector(amountSelector));
  const { t } = useTranslation();
  const clickstream = Clickstream.use();
  const cashierContext = getClickStreamCashierContext(FlowTypes.withdrawal);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const [submitGeneralError, setSubmitGeneralError] = useState<string>('');
  const [createRequisiteError, setCreateRequisiteError] = useState<string>('');
  const abortControllerRef = useAbortSubmitControllerRef();
  const [reInitTrigger, reinitData] = useLazyGetWithdrawalMethodsQuery();
  const { data: methodInfo = {}, isFetching, isSuccess, error: selectMethodError } = useGetWithdrawalMethodInfoQuery({
    payGroupId,
    currency,
  }, {
    skip: !currency,
  });
  const { data: initData } = reinitData;
  useSetGroupDataAttributes(currency, payGroupId);

  const {
    requiredFields,
    amountBlocks,
    amountRegExpFe,
    limits,
    merchantUserFee,
    payoutAccountVerifBlocked = false,
    payoutAccountVerifBlockedUntil = '',
    trustedContacts,
    allowNewPayoutAccount,
    supportUrl,
    viewData,
    additionalViewData,
    fee,
    processingCurrency,
    processingCurrencyRate,
    info,
    processingTimeTranslationKey,
  } = methodInfo as PayoutProcessMethodInfo;
  const isCarouselView = useAppSelector(isCarouselViewSelector);

  useEffect(() => {
    if (isSuccess && methodInfo) {
      deleteClickStreamPayGroupCashierContext();
      setClickStreamPayGroupCashierContext(methodName, limits || {});
    }
  }, [isSuccess]);

  useReinit({
    reInitTrigger,
    currency,
  });

  useSetNotificationsHook(viewData as Notifications);

  useSetInitDataToStore({
    initData: initData as UIPayment & UIPayout,
    flowType: FlowTypes.withdrawal,
    dispatch,
    navigate,
    payGroupId,
  });

  const [submitTrigger, withdrawalSubmitResult] = useLazySubmitWithdrawalQuery();
  const [requisiteTrigger, requisiteResult] = useLazyCreateRequisiteQuery();

  const withdrawalRes = withdrawalSubmitResult;
  const { error } = withdrawalRes;

  const [cardParam, setCardParam] = useState<Parameter>();
  const [fieldValues, setFieldValues] = useState<FieldValues>({});
  const [shouldRedirectForVerification, setRedirectForVerification] = useState<boolean>(false);
  const hasNeededVerifCardsOptionsCount = requiredFields?.filter((opt) => opt.verifyNewPayoutAccount).length === 1;
  const creaditCardField = requiredFields?.find(({ extendedType }) => extendedType === ExtendedTypes.CardPan || extendedType === ExtendedTypes.CreditCard);

  const sendBackClickstreamEvent = () => {
    const clEvent = getClickStreamEvent.cashier_method_back_icon_click;
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
  };

  useEffect(() => {
    document.addEventListener('СlickstreamBack', sendBackClickstreamEvent);
    dispatch(setDefaultPaySessionState());
    dispatch(setHeadersNavIconRedirectPath(`/${Routes.withdrawal}`));

    return () => {
      document.removeEventListener('СlickstreamBack', sendBackClickstreamEvent);
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      deleteClickStreamPayGroupCashierContext();
      setClickStreamPayGroupCashierContext(methodName, limits || {});
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error && (error as CustomServerError)?.errorCode === 17) {
      dispatch(setSessionExpired());
      return;
    }

    if (!selectMethodError) {
      return;
    }
    const errorObject = getErrorMessage(selectMethodError);
    dispatch(showErrorPopUp(errorObject));
  }, [
    selectMethodError,
    error,
  ]);

  useEffect(() => {
    const cardField = requiredFields?.find((param) => isCardPanSelectTypeField(param));
    setCardParam(cardField);
    const hasTrustedContacts = trustedContacts?.some((contact) => contact.value && contact.type);

    setRedirectForVerification(Boolean(hasNeededVerifCardsOptionsCount && hasTrustedContacts && allowNewPayoutAccount));
    dispatch(disableAccountVerification({
      accountVerifBlocked: payoutAccountVerifBlocked,
      accountVerifBlockedUntil: payoutAccountVerifBlockedUntil,
    }));
  }, [
    requiredFields,
    payoutAccountVerifBlocked,
    selectedCard,
  ]);

  useEffect(() => {
    if (requisiteResult.isSuccess) {
      dispatch(setRequisiteId(requisiteResult.data.requisiteId || null));
      handleRedirectForVerification({
        requiredFields,
        trustedContacts,
        fields: fieldValues,
        supportUrl,
        payoutAccountVerifBlocked,
        allowNewPayoutAccount,
        hasNeededVerifCardsOptionsCount,
        dispatch,
        navigate,
      });
    }
    if (requisiteResult?.error) {
      const { message } = getErrorMessage(requisiteResult.error);

      setCreateRequisiteError(message || '');
    }
  }, [requisiteResult]);

  useFrameChangeHeight(isSuccess);

  useFormContainerHook({
    error: withdrawalRes.error as CustomServerError,
    setSubmitGeneralError,
  });

  const sendSubmitClickStreamEvent = () => {
    if (isCarouselView) {
      const event = getClickStreamEvent.demetr_carousel_submit_click;
      clickstream.push(event, [cashierContext,
        payGroupContext]);
    }
    if (cardParam) {
      const event = getClickStreamEvent.cashier_payout_continue_button_click;
      clickstream.push(event, [cashierContext,
        payGroupContext]);
    } else {
      const clEvent = getClickStreamEvent.cashier_method_submit_button_click;
      clickstream.push(clEvent, [cashierContext,
        payGroupContext]);
    }
  };

  const submitCallback = (
    fields: FieldValues,
    setError: UseFormSetError<FieldValues>,
    dirtyFields: Partial<Record<keyof FieldValues, boolean>>,
  ) => {
    if (hasValidationError(fields, setError, {
      shouldValidateCard: !!creaditCardField,
      cardFieldName: creaditCardField?.name || '',
      amount,
    })) {
      return;
    }

    sendSubmitClickStreamEvent();
    const updatedFields = setNullToNotRequiredFields(requiredFields, fields, dirtyFields) as FieldValues;
    if (selectedCard.isNew && shouldRedirectForVerification) {
      const alternativeFieldName = Object.keys(fields).find((name) => !name.includes('amount'));
      const fieldName = cardParam?.name || alternativeFieldName || 'card';

      const value = typeof updatedFields[fieldName] === 'string'
        ? updatedFields[fieldName]
          .trim()
          .replace(/\s/gi, '')
        : updatedFields[fieldName];

      requisiteTrigger({
        pan: value,
      });
      setFieldValues(updatedFields);
      return;
    }

    const strategy = getSubmitStrategy(isOTPEnabled, false);
    const submitHandler = new SubmitHandler(strategy);
    abortControllerRef.current = new AbortController();

    const fieldsForRequest = prepareParametersForSubmit(prepareFieldsDataForSubmit(fields, requiredFields, selectedCard, cardParam), {});
    handleRedirectToOTP({
      isOTPEnabled,
      requiredFields,
      fields: fieldValues,
      dispatch,
    });

    submitHandler.handle({
      payGroupId,
      fields: fieldsForRequest,
      currency,
      amount: fields.amount,
      trigger: submitTrigger,
      navigate,
      signal: abortControllerRef.current.signal,
    });
  };

  const dropErrors = () => {
    setSubmitGeneralError('');
    setCreateRequisiteError('');
  };

  if (isFetching) {
    return <ModulorLoader />;
  }

  if (!submitGeneralError && !!withdrawalRes.data) {
    return (
      <SubmitFormWrapper
        paymentType={FlowTypes.withdrawal}
        redirectForm={withdrawalRes.data}
        methodName={methodName}
      />
    );
  }

  if (isSuccess) {
    const formConditions = {
      payoutAccountVerifBlocked,
    };

    return (
      <div className="withdrawal-form-wrapper">
        <WithdrawalForm
          descriptionUrl={info}
          submitCallback={submitCallback}
          limits={limits}
          amountRegExpFe={amountRegExpFe}
          parameters={requiredFields}
          amountBlocks={amountBlocks}
          cardParam={cardParam}
          merchantUserFee={merchantUserFee}
          fee={fee}
          currency={currency}
          additionalViewData={additionalViewData}
          isFetchingSubmitRequest={withdrawalRes?.isFetching}
          processingCurrency={processingCurrency}
          processingCurrencyRate={processingCurrencyRate}
          formConditions={formConditions}
          processingTimeTranslationKey={processingTimeTranslationKey}
        />
        {(submitGeneralError || createRequisiteError) && (
          <Toast
            variant="error"
            visible={Boolean(submitGeneralError || createRequisiteError)}
            text={t(submitGeneralError || createRequisiteError)}
            multiline
            rightIcon={(
              <Icon
                name="close"
                onClick={dropErrors}
              />
            )}
            onHide={dropErrors}
            duration={2500}
          />
        )}
      </div>
    );
  }

  return null;
};
