/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import {
  CSSProperties,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  Dispatch,
} from 'react';
import { PayloadAction, Dispatch as ToolkitDispatch } from '@reduxjs/toolkit';
import { NavigateFunction, To, useNavigate, useParams } from 'react-router-dom';
import { FieldErrorsImpl, FieldValues, UseFormTrigger } from 'react-hook-form';
import { getFirstConsistentlyInteractive } from 'tti-polyfill';
import qs from 'query-string';
import { onFID } from 'web-vitals';
import { CustomServerError, DepositSubmitRequestProps } from 'store/services/depositAPI';
import {
  CalculateAmountWithTaxesResponse,
  getAmountWithTaxes,
  getNonTaxableAmount,
  getTax,
  UIPayment,
  UIPayout,
} from 'store/formatters';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { DynamicTypeObject, FlatStringObject } from 'commonTypes';
import { FlowTypes, SELECTED_METHOD_ID } from 'store/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { depositTaxSelector, isAutoSubmittedSelector, setIsAutoSubmitted } from 'store/slices/deposit';
import { withdrawalTaxSelector } from 'store/slices/withdrawal';
import {
  getPlatformsViewportSelector,
  isSessionExpiredSelector,
  selectedPayGroupIdSelector,
  sessionIdSelector,
  setPlatformsViewport, setSessionExpired,
  setUserBalance,
} from 'store/slices/global';
import {
  PayHubCashierContractsTaxResponse as TaxResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse, UserBonusInfo,
  PlatformsViewport,
} from 'store/models';
import { isValidJSON } from 'utils/validator';
import {
  WIDGET_API,
  getAppHeight,
  getGroupAttributes,
  parseQuery,
  prepareFieldsDataForSubmit,
  softLocalStorageGet,
  stringToInt,
  LayoutType,
  getLayoutType,
  getIsOpenedInPopUp, PLATFORM_MIN_HEIGHT,
} from '../utils';
import { setFingerprint } from '../services/fingerprint';
import { notificationsSelector } from '../store/slices/notifications';
import IntegrationCommunication from '../services/integration-communication';
import { setBonusesInfo } from '../store/slices/bonus';
import { formRequestBody } from './payUtils';
import { setDepositInitDataToStore } from './Deposit/utils';
import { setWithdrawalInitDataToStore } from './Withdrawal/utils';

export const useBonusesHook = (bonusInfo?: UserBonusInfo[] | null): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bonusInfo) {
      dispatch(setBonusesInfo(bonusInfo));
    }
  }, [bonusInfo]);
};

type Metric = {
  name: string;
  value?: string;
};

type Tags = {
  issuer: string;
};

type RequestMetricsData = {
  metrics: Metric[];
  tags: Tags;
};

export const useGetSessionId = (): string => {
  const { session_id = '' } = qs.parse(window?.location?.search || '');
  const storedSessionId = useAppSelector(sessionIdSelector);

  return (session_id || storedSessionId || '') as string;
};

const prepareDataForRequest = (
  metricName: string,
  value?: number | string,
): RequestMetricsData => {
  const requestData: RequestMetricsData = {
    metrics: [],
    tags: {
      issuer: 'widget',
    },
  };

  if (metricName === 'TTI' && value) {
    const ttiMetric = {
      name: 'TTI',
      value: `${Math.round(+value)}`,
    };

    requestData.metrics = [
      ...requestData.metrics,
      ttiMetric];
  }

  if (metricName === 'FID' && value) {
    const fidMetric = {
      name: 'FID',
      value: `${Math.round(+value)}`,
    };

    requestData.metrics = [
      ...requestData.metrics,
      fidMetric];
  }

  return requestData;
};

const postMetric = async (
  requestUrl: string,
  sessionId: string,
  requestData: RequestMetricsData,
) => {
  const body = JSON.stringify(requestData);
  await fetch(requestUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'default',
    headers: {
      'X-Session-Id': sessionId,
      'content-type': 'application/json',
    },
    body,
  });
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const fetchMetricsData = (sessionId: string, dispatch: ToolkitDispatch) => {
  let hasTTI = false;
  let hasFID = false;
  const flowType = window.location.pathname.includes(FlowTypes.deposit) ? 'payments' : 'payouts';
  const requestUrl = `${WIDGET_API}/${flowType}/metrics`;

  if (!hasFID) {
    onFID(((metric) => {
      try {
        const [{ duration }] = metric.entries;
        if (duration) {
          const requestData = prepareDataForRequest('FID', duration);

          postMetric(requestUrl, sessionId, requestData)
            .then(() => {
              hasFID = true;
            })
            // eslint-disable-next-line no-console
            .catch((e) => console.error(e));
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);

        if (e && (e as CustomServerError)?.errorCode === 17) {
          dispatch(setSessionExpired());
        }
      }
    }));
  }
  if (!hasTTI) {
    getFirstConsistentlyInteractive().then((tti) => {
      if (tti) {
        const requestData = prepareDataForRequest('TTI', tti);
        postMetric(requestUrl, sessionId, requestData)
          .then(() => {
            hasTTI = true;
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);

            if (e && (e as CustomServerError)?.errorCode === 17) {
              dispatch(setSessionExpired());
            }
          });
      }
    });
  }
};

export const useMetricsRequest = (dispatch: ToolkitDispatch) => {
  const params = parseQuery(window.location.search);
  const { metrics } = params;
  const sessionId = useGetSessionId();
  const isSessionExpired = useAppSelector(isSessionExpiredSelector);

  useEffect(() => {
    if (!metrics || metrics === '0' || !sessionId || isSessionExpired) {
      return;
    }
    fetchMetricsData(sessionId, dispatch);
  }, []);
};

interface UseDepositMethodHookProps {
  error?: CustomServerError;
  setSubmitGeneralError: (error: string) => void;
}

export const useFormContainerHook = (props: UseDepositMethodHookProps): void => {
  const {
    error,
    setSubmitGeneralError,
  } = props;

  useEffect(
    () => {
      setFingerprint();
    },
    [],
  );

  useEffect(() => {
    if (error && error.errorCode !== 17) {
      setSubmitGeneralError(error.errorMessage || '');
    }
  }, [error]);
};

export const useGetPayGroupId = (): number => {
  const { provider = '' } = useParams();
  const payGroupId = useAppSelector(selectedPayGroupIdSelector);
  const storedPayGroupId = softLocalStorageGet(SELECTED_METHOD_ID);
  const { payGroupIdAttr } = getGroupAttributes();

  return stringToInt(provider) || payGroupId || (
    storedPayGroupId && stringToInt(storedPayGroupId)) || payGroupIdAttr;
};
interface TaxSelector {
  (payment: { deposit?: UIPayment; withdrawal?: UIPayout }): TaxResponse;
}
interface UseGetTaxInfo {
  tax: TaxResponse;
  calculateNonTaxableAmount: number;
  amountWithTaxes: CalculateAmountWithTaxesResponse;
}

export const useGetTaxInfo = (amount: number, useCase: string): UseGetTaxInfo => {
  const FLOW_TYPE_SELECTOR_MAPPING: {
    [key: string]: TaxSelector;
  } = {
    [FlowTypes.deposit]: depositTaxSelector,
    [FlowTypes.withdrawal]: withdrawalTaxSelector,
  };
  const tax = useAppSelector(FLOW_TYPE_SELECTOR_MAPPING[useCase]);
  const calculateNonTaxableAmount: number = getNonTaxableAmount(tax);
  const taxValue = getTax({
    tax,
    amount,
  });
  const isExtraTaxInfoShown = (amount > calculateNonTaxableAmount) && !!taxValue;
  const amountWithTaxes = getAmountWithTaxes({
    isExtraTaxInfoShown,
    tax,
    amount,
    calculateNonTaxableAmount,
    taxValue,
  });
  return {
    tax,
    calculateNonTaxableAmount,
    amountWithTaxes,
  };
};

export const useDetectNewErrors = (errors: Partial<FieldErrorsImpl<DynamicTypeObject>>) => {
  const [fieldErrorsCount, setFieldErrorsCount] = useState(0);
  const [hasNewErrors, sethasNewErrors] = useState(false);

  const errorsCount = Object.keys(errors).length;

  useEffect(() => {
    sethasNewErrors(fieldErrorsCount !== errorsCount);
    setFieldErrorsCount(errorsCount);
  }, [errorsCount]);

  return {
    hasNewErrors,
  };
};

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (event: Event) => void) => {
  useEffect(() => {
    const listener = (e: Event) => {
      const targetNode = e.target;
      if (targetNode instanceof Node && (!ref.current || ref.current.contains(targetNode))) {
        return;
      }
      handler(e);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [
    ref,
    handler,
  ]);
};

interface PrefillAutosubmitProps {
  amountValue: number;
  payGroupId: number;
  currency: string;
  presetCurrency?: string | null;
  autoSubmit?: boolean;
  isMethodLoaded?: boolean;
  isCheckout?: boolean;
  isValid: boolean;
  values: FieldValues;
  validate: UseFormTrigger<FieldValues>;
  submitDepositTrigger?: LazyQueryTrigger<QueryDefinition<DepositSubmitRequestProps,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never, PayHubWidgetContractsSubmitPayoutResponse | PayHubWidgetContractsSubmitPaymentResponse, 'depositAPI'>>;
}

export const useAbortSubmitControllerRef = () => {
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleCancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      handleCancelRequest();
    };
  }, []);

  return abortControllerRef;
};

export const usePrefillAutosubmit = ({
  autoSubmit,
  isMethodLoaded,
  amountValue,
  currency,
  payGroupId,
  validate,
  submitDepositTrigger,
  values,
  isCheckout,
  isValid,
}: PrefillAutosubmitProps) => {
  const dispatch = useAppDispatch();
  const isAutoSubmitted = useAppSelector(isAutoSubmittedSelector);
  const abortControllerRef = useAbortSubmitControllerRef();

  useEffect(() => () => {
    dispatch(setIsAutoSubmitted(false));
  }, []);

  useEffect(() => {
    if (autoSubmit && isMethodLoaded && !isAutoSubmitted && submitDepositTrigger) {
      validate().then((hasValidFields) => {
        if (hasValidFields) {
          abortControllerRef.current = new AbortController();
          submitDepositTrigger({
            payGroupId,
            requestBody: formRequestBody({
              amount: `${amountValue}`,
              fields: isCheckout ? prepareFieldsDataForSubmit(values) : values,
              currency,
            }),
            signal: abortControllerRef.current.signal,
          });
          dispatch(setIsAutoSubmitted(true));
        }
      });
    }
  }, [
    isValid,
    autoSubmit,
    isAutoSubmitted,
    isMethodLoaded,
    submitDepositTrigger,
  ]);
};

export const useSetGroupDataAttributes = (currency: string, paygroupId: number) => {
  useEffect(() => {
    document.body.dataset.paygroupId = `${paygroupId}`;
    document.body.dataset.currency = currency;
  }, []);
};

interface UseReinit {
  reInitTrigger: () => void;
  currency: string;
}
export const useReinit = (props: UseReinit) => {
  const {
    reInitTrigger,
    currency,
  } = props;

  useEffect(() => {
    if (currency) {
      return;
    }

    reInitTrigger();
  }, []);
};

interface UseSetInitDataToStore {
  initData: UIPayment & UIPayout;
  flowType: FlowTypes;
  dispatch: ToolkitDispatch;
  navigate: NavigateFunction;
  payGroupId: number;
}

export const useSetInitDataToStore = (props: UseSetInitDataToStore) => {
  const {
    initData,
    flowType,
    dispatch,
    navigate,
    payGroupId,
  } = props;
  useEffect(() => {
    const setFunction = flowType === FlowTypes.deposit
      ? setDepositInitDataToStore
      : setWithdrawalInitDataToStore;

    if (initData) {
      const {
        userBalance,
      } = initData;

      dispatch(setUserBalance(userBalance));
      setFunction({
        initData,
        dispatch,
        navigate,
        payGroupId,
      });
    }
  }, [initData]);
};

export const useNavigateWithSearch = () => {
  const navigate = useNavigate();

  const nav = (to: To, options: FlatStringObject) => {
    if (Number.isInteger(to)) {
      navigate(to);
      return;
    }

    const { search = '' } = window.location;

    navigate({
      pathname: `${to}`,
      search,
    }, options);
  };

  return nav as NavigateFunction;
};

type FrameChangeHeightOptions = {
  skip?: boolean;
  applyPlatformHeights?: boolean;
};

const setViewPortData = (
  e: MessageEvent,
  dispatch: Dispatch<PayloadAction<PlatformsViewport>>,
) => {
  const { data } = e;
  if (!isValidJSON(data)) {
    return;
  }

  const preparedData = JSON.parse(data);
  if (preparedData.id === 'hostHeight') {
    dispatch(setPlatformsViewport(preparedData.data));
  }
};

export const useFrameChangeHeight = (flag: boolean, options?: FrameChangeHeightOptions) => {
  const dispatch = useAppDispatch();
  const {
    modalHeight = PLATFORM_MIN_HEIGHT,
    headerHeight = 0,
    navBarHeight = 0,
    height = PLATFORM_MIN_HEIGHT,
    pageTop = 0,
  } = useAppSelector(getPlatformsViewportSelector) || {};
  const [initialViewPortHeight, setViewPortHeight] = useState(PLATFORM_MIN_HEIGHT);

  useEffect(() => {
    if (height) {
      setViewPortHeight(height);
    }
  }, []);

  const payGroupId = useGetPayGroupId();
  const notifications = useAppSelector(notificationsSelector(payGroupId));
  const { skip, applyPlatformHeights } = options || {};

  useLayoutEffect(() => {
    window.addEventListener('message', (e) => setViewPortData(e, dispatch));
  }, []);

  useLayoutEffect(() => {
    if (skip) {
      return;
    }

    if (flag) {
      const requiredAppHeight = getAppHeight({
        modalHeight,
        headerHeight,
        navBarHeight,
        height: initialViewPortHeight,
        pageTop,
      }, applyPlatformHeights);
      setTimeout(() => {
        IntegrationCommunication.sendMessage({
          id: 'frameChangedHeight',
          value: requiredAppHeight,
        });
      }, 0);
    }
    return () => {
      if (flag) {
        window.removeEventListener('message', (e) => setViewPortData(e, dispatch));
      }
    };
  }, [
    flag,
    notifications,
    skip,
    applyPlatformHeights,
  ]);
};

const getMobileSubmitButtonStyles = (
  keyboardHeight = 0,
): CSSProperties => {
  const isMobile = getLayoutType() === LayoutType.mobile;

  return (isMobile ? ({
    position: 'fixed',
    bottom: keyboardHeight,
    right: 0,
    left: 0,
    zIndex: 999,
    padding: '15px 0',
    backgroundColor: 'var(--background-main)',
    borderTop: '1px solid var(--divider,var(--divider-main))',
    transition: '0.3s',
  }) : {});
};

type DefineStyleProps = {
  height: number;
  pageTop: number;
  navBarHeight: number;
  headerHeight: number;
  setFormStyles: Dispatch<SetStateAction<CSSProperties>>;
};

const addDynamicStyles = ({
  height,
  pageTop,
  navBarHeight,
  headerHeight,
  setFormStyles,
}: DefineStyleProps) => {
  const viewportHeight = window.innerHeight;

  if (height < viewportHeight) {
    const keyboardHeight = viewportHeight - height - pageTop + navBarHeight + headerHeight;
    setFormStyles(getMobileSubmitButtonStyles(keyboardHeight));
  } else {
    setFormStyles(getMobileSubmitButtonStyles(0));
  }
};

export const useVisualViewportSubmitButton = (isDisabled?: boolean) => {
  const platformViewPort = useAppSelector(getPlatformsViewportSelector);
  const {
    headerHeight = 0,
    navBarHeight = 0,
    height,
    pageTop = 0,
  } = platformViewPort || {};

  const hasPlatformViewPortData = Boolean(Object.keys(platformViewPort || {}).length);
  const isMobile = getLayoutType() === LayoutType.mobile;
  const isOpenedInPopup = getIsOpenedInPopUp();

  const [formStyles, setFormStyles] = useState(
    hasPlatformViewPortData && !isOpenedInPopup && !isDisabled ? getMobileSubmitButtonStyles() : {},
  );

  const updateFormStyles = useCallback(() => {
    const shouldApplyDynamicStyles = height && hasPlatformViewPortData && !isOpenedInPopup && !isDisabled;
    const shouldResetDynamicStyles = !isOpenedInPopup && isDisabled;
    if (shouldApplyDynamicStyles) {
      addDynamicStyles({
        height,
        pageTop,
        navBarHeight,
        headerHeight,
        setFormStyles,
      });
    }
    if (shouldResetDynamicStyles) {
      setFormStyles({});
    }
  }, [
    height,
    headerHeight,
    navBarHeight,
    pageTop,
    isDisabled,
  ]);

  useEffect(() => {
    updateFormStyles();

    const handleResize = () => {
      updateFormStyles();
    };
    if (!isOpenedInPopup && !isDisabled) {
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleResize);
    }
    return () => {
      if (!isOpenedInPopup && !isDisabled) {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleResize);
      }
    };
  }, [
    updateFormStyles,
    isMobile,
    isOpenedInPopup,
    isDisabled,
  ]);

  return {
    formStyles,
  };
};
