import { useEffect, useState } from 'react';
import { Icon, Toast } from '@modulor/react';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import {
  CustomServerError,
  useGetDepositMethodInfoQuery,
  useLazyGetDepositMethodsQuery,
  useLazySubmitDepositQuery,
} from 'store/services/depositAPI';
import { FlowTypes } from 'store/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  Notifications,
  PaymentProcessMethodInfo,
} from 'store/models';
import {
  currentDepositMethodCurrencySelector,
  isAutoSubmittedInitSelector,
  methodNameSelector,
} from 'store/slices/deposit';
import IntegrationCommunication from 'services/integration-communication';
import { getErrorMessage, HEADER_ICON } from 'utils';
import { showErrorPopUp } from 'store/slices/errorPopUp';
import { CheckoutController } from 'Modulor/Checkout/CheckoutController';
import {
  Clickstream,
  deleteClickStreamPayGroupCashierContext,
  getClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
  setClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { hasValidationError } from 'utils/form';
import { Routes } from 'Modulor/router';
import {
  amountSelector,
  setAmount,
  setHeadersNavIcon,
  setHeadersNavIconRedirectPath, setReturnUrls,
  setSessionExpired,
} from 'store/slices/global';
import { UIPayment } from 'store/formatters';
import { isCarouselViewSelector } from 'store/slices/carousel';
import { setDefaultPaySessionState } from 'store/slices/paySessionState';
import { isOTPEnabledSelector, toggleOTPMode } from 'store/slices/otp';
import { useTranslation } from 'react-i18next';
import { SubmitFormWrapper } from '../components/SubmitForm/SubmitFormWrapper';
import ModulorLoader from '../components/ModulorLoader';
import { DepositForm } from './DepositForm';
import {
  useAbortSubmitControllerRef,
  useFormContainerHook,
  useFrameChangeHeight,
  useGetPayGroupId,
  useReinit,
  useSetGroupDataAttributes,
  useSetInitDataToStore,
  useNavigateWithSearch,
} from '../hooks';
import { SubmitHandler } from '../SubmitStrategy/SubmitHandler';
import {
  getSubmitStrategy,
  getValidPresettedAmount,
  prepareFieldsDataForSubmit,
} from './utils';
import { useSetNotificationsHook } from '../components/Notifications/notificationsHooks';
import { handleRedirectToOTP } from '../OTP/utils';

export const DepositMethod = () => {
  const clickstream = Clickstream.use();
  const cashierContext = getClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const navigate = useNavigateWithSearch();
  const dispatch = useAppDispatch();
  const amount = parseFloat(useAppSelector(amountSelector));
  const methodName = useAppSelector(methodNameSelector);
  const isOTPEnabled = useAppSelector(isOTPEnabledSelector);
  const { t } = useTranslation();
  const payGroupId = useGetPayGroupId();
  const currency = useAppSelector(currentDepositMethodCurrencySelector);
  const isAutoSubmittedInitMethod = useAppSelector(isAutoSubmittedInitSelector);
  const abortControllerRef = useAbortSubmitControllerRef();
  const [submitGeneralError, setSubmitGeneralError] = useState<string>('');
  useSetGroupDataAttributes(currency, payGroupId);
  const [reInitTrigger, reinitData] = useLazyGetDepositMethodsQuery();
  const { data: methodInfo, isFetching, isSuccess, error: selectMethodError } = useGetDepositMethodInfoQuery({
    payGroupId,
    currency,
  }, {
    skip: !currency,
  });
  const { data: initData } = reinitData;
  const {
    requiredFields,
    amountBlocks = [],
    amountRegExpFe,
    limits,
    isCheckout,
    merchantUserFee,
    viewData,
    additionalViewData,
    autoSubmit,
    presetAmount,
    allowPresetAmount,
    presetCurrency,
    fee,
    processingCurrency,
    processingCurrencyRate,
    info,
    processingTimeTranslationKey,
  } = methodInfo as PaymentProcessMethodInfo || {};
  const {
    merchantUrl,
    merchantSuccessUrl,
    merchantFailUrl,
    merchantProcessingUrl,
    merchantRedirectType,
  } = initData || {};
  const isCarouselView = useAppSelector(isCarouselViewSelector);

  const isAutoSubmit = autoSubmit || isAutoSubmittedInitMethod;

  useSetNotificationsHook(viewData as Notifications);

  useReinit({
    reInitTrigger,
    currency,
  });

  const strategy = getSubmitStrategy(isOTPEnabled, isCheckout);
  const submitHandler = new SubmitHandler(strategy);

  const [submitDepositTrigger, result] = useLazySubmitDepositQuery();

  const {
    error,
    data,
    isError,
    isFetching: isFetchingSubmitRequest,
  } = result;

  useSetInitDataToStore({
    initData: initData as UIPayment,
    flowType: FlowTypes.deposit,
    dispatch,
    navigate,
    payGroupId,
  });

  useEffect(() => {
    if (error && (error as CustomServerError)?.errorCode === 17) {
      dispatch(setSessionExpired());
      return;
    }
    if (isError && isAutoSubmit) {
      navigate(Routes.depositError);
    }
    if (!selectMethodError) {
      return;
    }

    const errorObject = getErrorMessage(selectMethodError);
    dispatch(showErrorPopUp(errorObject));
  }, [
    selectMethodError,
    error,
  ]);

  useEffect(() => {
    if (!requiredFields?.length) {
      return;
    }

    if (requiredFields.some(({ isOtpRequisites }) => isOtpRequisites)) {
      dispatch(toggleOTPMode(true));
    }
  }, [requiredFields]);

  useEffect(() => {
    if (allowPresetAmount || isAutoSubmit) {
      const amountValue = getValidPresettedAmount({
        presetAmount: presetAmount || 0,
        amountBlocks,
        min: limits?.min,
        max: limits?.max,
      });
      dispatch(setAmount(`${amountValue}`));
    }
    return () => {
      if (allowPresetAmount) {
        dispatch(setAmount('0'));
      }
    };
  }, [
    allowPresetAmount,
    autoSubmit,
  ]);

  useEffect(() => {
    if (initData && Object.keys(initData).length) {
      dispatch(setReturnUrls({
        merchantUrl,
        merchantSuccessUrl,
        merchantFailUrl,
        merchantProcessingUrl,
        merchantRedirectType,
      }));
    }
  }, [initData]);

  useFrameChangeHeight(isSuccess);

  useFormContainerHook({
    error: error as CustomServerError,
    setSubmitGeneralError,
  });

  const submitCallback = (
    fields: FieldValues,
    setError: UseFormSetError<FieldValues>,
    dirtyFields: Partial<Record<keyof FieldValues, boolean>>,
  ) => {
    if (hasValidationError(fields, setError, {
      amount,
    })) {
      return;
    }

    if (isCarouselView) {
      const event = getClickStreamEvent.demetr_carousel_submit_click;
      clickstream.push(event, [cashierContext,
        payGroupContext]);
    }

    const clEvent = getClickStreamEvent.cashier_method_submit_button_click;
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
    IntegrationCommunication.sendMessage({
      id: `${FlowTypes.deposit}Submit`,
      value: {
        amount: fields.amount,
        currency,
      },
    });
    handleRedirectToOTP({
      isOTPEnabled,
      fields,
      requiredFields,
      dispatch,
    });

    abortControllerRef.current = new AbortController();
    submitHandler.handle({
      payGroupId,
      fields: prepareFieldsDataForSubmit(fields, null, requiredFields, dirtyFields),
      currency,
      amount: fields.amount,
      trigger: submitDepositTrigger,
      navigate,
      signal: abortControllerRef.current.signal,
    });
  };

  const sendBackClickstreamEvent = () => {
    const clEvent = getClickStreamEvent.cashier_method_back_icon_click;
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
  };

  useEffect(() => {
    document.addEventListener('СlickstreamBack', sendBackClickstreamEvent);
    dispatch(setHeadersNavIcon(HEADER_ICON.back));
    dispatch(setHeadersNavIconRedirectPath(`/${Routes.deposit}`));
    dispatch(setDefaultPaySessionState());

    return () => {
      document.removeEventListener('СlickstreamBack', sendBackClickstreamEvent);
    };
  }, []);

  useEffect(() => {
    if (isSuccess && methodInfo) {
      deleteClickStreamPayGroupCashierContext();
      setClickStreamPayGroupCashierContext(methodName, limits || {});
    }
  }, [isSuccess]);

  if (isFetching) {
    return <ModulorLoader />;
  }

  if (!submitGeneralError && data) {
    return (
      <SubmitFormWrapper
        paymentType={FlowTypes.deposit}
        redirectForm={data}
        methodName={methodName}
      />
    );
  }

  if (isSuccess && methodInfo) {
    return (
      <div className="deposit-form-wrapper">
        {isCheckout ? (
          <CheckoutController
            amount={amount}
            withAmount
            amountRegExpFe={amountRegExpFe}
            limits={limits}
            currency={currency}
            presetCurrency={presetCurrency}
            payGroupId={payGroupId}
            methodInfo={methodInfo}
            isMethodLoadSuccess={isSuccess}
            additionalViewData={additionalViewData}
            merchantUserFee={merchantUserFee}
            fee={fee}
            processingCurrency={processingCurrency}
            processingCurrencyRate={processingCurrencyRate}
          />
        ) : (
          <DepositForm
            descriptionUrl={info}
            amount={amount}
            submitCallback={submitCallback}
            limits={limits}
            amountRegExpFe={amountRegExpFe}
            formConfig={requiredFields}
            amountBlocks={amountBlocks}
            merchantUserFee={merchantUserFee}
            currency={currency}
            isFetchingSubmitRequest={isFetchingSubmitRequest}
            presetCurrency={presetCurrency}
            isAutoSubmit={isAutoSubmit}
            isMethodLoaded={isSuccess}
            submitDepositTrigger={submitDepositTrigger}
            payGroupId={payGroupId}
            additionalViewData={additionalViewData}
            presetAmount={presetAmount}
            fee={fee}
            processingCurrency={processingCurrency}
            processingCurrencyRate={processingCurrencyRate}
            processingTimeTranslationKey={processingTimeTranslationKey}
          />
        )}
        {submitGeneralError && (
          <Toast
            variant="error"
            visible={Boolean(submitGeneralError)}
            text={t(submitGeneralError)}
            multiline
            rightIcon={(
              <Icon
                name="close"
                onClick={
                  () => setSubmitGeneralError('')
                }
              />
            )}
            onHide={() => setSubmitGeneralError('')}
            duration={2500}
          />
        )}
      </div>
    );
  }

  return null;
};
