import { TFunction } from 'commonTypes';
import { MerchantUserFeeResponce } from 'store/models';
import { NAMESPACES } from '../services/constants';

export const getMerchantUserFeeTranslation = (fee: MerchantUserFeeResponce, t: TFunction) => {
  const percentageTranslation = fee?.percentage ? t(`${NAMESPACES.PW_KEYS}:PH.FEE.MESSAGE`, {
    percentage: `${fee.percentage}`,
  }) : '';
  const fixedFee = fee?.fix ? ` + ${fee.fix}` : '';

  return `${percentageTranslation}${fixedFee}`;
};
