/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import { WIDGET_API, setReqHeaders } from 'utils';
import { PayHubWidgetContractsSubmitPaymentResponse } from '../models';

interface Page3dsRequest {
  pares?: string;
  cres?: string;
  md?: string;
}

export const page3dsAPI = createApi({
  reducerPath: 'page3dsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: WIDGET_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      request3ds: builder.query< PayHubWidgetContractsSubmitPaymentResponse, Page3dsRequest >({
        query: (body) => ({
          url: 'payments/3ds-result',
          method: 'POST',
          body,
        }),
      }),
    };
  },
});

export const {
  useLazyRequest3dsQuery,
} = page3dsAPI;
export default page3dsAPI.reducer;
