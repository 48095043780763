import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PayHubCashierContractsCashierSettingsResponse,
  PayHubCashierContractsPaymentsSelectPaymentGroupResponse,
  PayHubCashierContractsTaxResponse,
} from 'store/models';
import { getGroupAttributes } from 'utils/common';
import { extendedDepositAPI } from 'store/services/depositAPI';
import { UIPayment, UIPaymentGroups } from '../formatters';
import { DEFAULT_EMPTY_CURRENCY_ALPHA3 } from '../constants';

interface DepositSlice extends UIPayment {
  currentMethod?: UIPaymentGroups;
  isAutoSubmitted?: boolean;
  skipAutoSelectMethodAfterDeclinePage?: boolean;
  responceByReqId?: Record<string, PayHubCashierContractsPaymentsSelectPaymentGroupResponse | null>;
  requestGroupIds?: Record<string, string | null>;
}

const initialState: DepositSlice = {
  methodsTypes: [],
  isReady: false,
  canAutoRedirect: false,
  isRequestPending: false,
  submitPaymentReady: false,
  currencyId: null,
  currencyAlpha3: null,
  widgetSettings: {},
  methods: [],
  initCarouselMethods: [],
  growthbookFeatures: [],
  viewData: [],
  tax: {},
  userBalance: 0,
  isAutoSubmitted: false,
  skipAutoSelectMethodAfterDeclinePage: false,
  responceByReqId: {},
  requestGroupIds: {},
  userBonuses: null,
};

export const depositSlice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    setWidgetSettings(state, action: PayloadAction<PayHubCashierContractsCashierSettingsResponse>) {
      return {
        ...state,
        widgetSettings: action.payload,
      };
    },
    setCurrentMethod: (state, action: PayloadAction<UIPaymentGroups>) => ({
      ...state,
      currentMethod: action.payload,
    }),
    setDepositTax: (state, action: PayloadAction<PayHubCashierContractsTaxResponse | undefined>) => ({
      ...state,
      tax: action.payload,
    }),
    setIsAutoSubmitted: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAutoSubmitted: payload,
    }),
    cleanCurrentMethod(state) {
      return {
        ...state,
        isAutoSubmitted: false,
        currentMethod: undefined,
      };
    },
    setSkippingAfterDeclinePage(state) {
      return {
        ...state,
        skipAutoSelectMethodAfterDeclinePage: true,
      };
    },
    resetSkippingAfterDeclinePage(state) {
      return {
        ...state,
        skipAutoSelectMethodAfterDeclinePage: false,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(extendedDepositAPI.endpoints.getDepositMethodInfo.matchPending, (state, action) => {
      const { payGroupId } = action.meta.arg.originalArgs;
      if (state.requestGroupIds) {
        state.requestGroupIds[payGroupId.toString()] = action.meta.requestId;
      }
    });
    builder.addMatcher(extendedDepositAPI.endpoints.getDepositMethodInfo.matchFulfilled, (state, action) => {
      const { payGroupId } = action.meta.arg.originalArgs;
      const requestId = state.requestGroupIds ? state.requestGroupIds[payGroupId] : null;
      if (requestId === action.meta.requestId && state.responceByReqId) {
        state.responceByReqId[action.meta.requestId] = action.payload;
      }
    });
  },
});

export const depositTaxSelector = (state: { deposit?: DepositSlice }) => {
  const { deposit } = state;
  if (deposit) {
    return deposit.tax || {};
  }
  return {};
};

export const currentDepositMethodSelector = (state: { deposit?: DepositSlice }) => state
  .deposit?.currentMethod;

export const methodNameSelector = (state: { deposit?: DepositSlice }) => state
  .deposit?.currentMethod?.displayName || '';

export const isSelfTargetSelector = (state: { deposit?: DepositSlice }) => Boolean(
  state?.deposit?.currentMethod?.isSelfTarget,
);

export const isAutoSubmittedSelector = ({
  deposit,
}: { deposit : DepositSlice }) => deposit.isAutoSubmitted;

export const skipAutoSelectMethodAfterDeclinePageSelector = ({
  deposit,
}: { deposit : DepositSlice }) => Boolean(deposit.skipAutoSelectMethodAfterDeclinePage);

export const currentDepositMethodCurrencySelector = (state: { deposit?: DepositSlice }) => state
  ?.deposit?.currentMethod?.currencyAlpha3 || getGroupAttributes().currencyAttr || DEFAULT_EMPTY_CURRENCY_ALPHA3;

export const isAutoSubmittedInitSelector = (state: { deposit?: DepositSlice }) => state
  .deposit?.currentMethod?.autoSubmit || false;

export const requestIdSelector = (deposit: DepositSlice, payGroupId: number) => deposit?.requestGroupIds?.[payGroupId];

export const methodInfoSelector = ({ deposit }: {
  deposit: DepositSlice;
}, payGroupId: number) => {
  const requestId = requestIdSelector(deposit, payGroupId);
  return typeof requestId === 'string' && deposit.responceByReqId ? deposit.responceByReqId[requestId] : null;
};

export const currencyDisplaySelector = (state: { deposit: DepositSlice }) => (
  state.deposit.widgetSettings.currency_display_setting_id
);

export const {
  setCurrentMethod,
  setDepositTax,
  cleanCurrentMethod,
  setIsAutoSubmitted,
  setSkippingAfterDeclinePage,
  resetSkippingAfterDeclinePage,
  setWidgetSettings,
} = depositSlice.actions;
export default depositSlice.reducer;
