import { FC, useEffect, useRef, useState } from 'react';
import clx from 'classnames';
import { Button, Icon, Tooltip, Typography } from '@modulor/react';
import { useOnClickOutside } from 'Modulor/hooks';
import { MerchantUserFeeResponce } from 'store/models';
import { displayTooltip } from 'utils/common';
import { getMerchantUserFeeTranslation } from 'utils/fee';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';

import './Fee.scss';

interface FeeProps {
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
}

export const Fee: FC<FeeProps> = ({ merchantUserFee, fee }) => {
  const { t } = useTranslation();
  const [isOpenedHint, showHint] = useState(false);
  const hintRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(hintRef, () => showHint(false));

  useEffect(() => {
    displayTooltip(false);
  }, []);

  if (!merchantUserFee) {
    return null;
  }

  const toggleHint = () => {
    if (isOpenedHint) {
      showHint(false);
      displayTooltip(false);
      return;
    }
    displayTooltip(true);
    showHint(true);
  };

  const feeText = getMerchantUserFeeTranslation(merchantUserFee, t);

  return (
    <div
      className={clx('fee-container', {
        'is-opened': isOpenedHint,
      })}
      ref={hintRef}
    >
      <Typography
        variant="subhead-regular"
        color="text-body"
        tag="p"
      >
        {feeText}
      </Typography>
      <Tooltip
        show={isOpenedHint}
        className="info-tooltip"
        heading={t(`${NAMESPACES.PW_KEYS}:PH.TITLE.TOOLTIP.COMMISSION`)}
        text={t(`${NAMESPACES.PW_KEYS}:PH.DESCRIPTION.TOOLTIP.COMMISSION`) || ''}
        button={(
          <Button
            variant="primary"
            className="info-tooltip-button"
            onClick={toggleHint}
            label={t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`)}
          />
  )}
      >
        {[<Icon
          key="info-icon"
          onClick={toggleHint}
          className="info-icon"
          name="info_circle_outlined"
        />]}
      </Tooltip>
    </div>
  );
};
