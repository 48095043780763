/* eslint-disable react/no-array-index-key */
import { MouseEvent } from 'react';
import clx from 'classnames';
import { Content, Icon, Image, ListCell, ListView, Typography } from '@modulor/react';
import { DisabledMethodsGroups, UIPayoutGroups } from 'store/formatters';
import { useAppSelector } from 'store/hooks';
import { initialThemeSelector, logoSizesSelector } from 'store/slices/global';
import { useTranslation } from 'react-i18next';
import {
  formMethodSubTitle,
  getDisabledMethods,
  getLayoutType, getTheme, getTranslationMessage,
  getTwoMethodColumns,
  GROUPS,
  LayoutType,
  updateLogoUrlViaTheme,
} from 'utils';
import { WithdrawalMethodsListDisabled } from './WithdrawalMethodsListDisabled';
import { NAMESPACES } from '../../services/constants';

interface WithdrawalMethodGroupListProps {
  name: GROUPS;
  list: UIPayoutGroups[];
  handleSelectWithdrawalMethod: (m: UIPayoutGroups) => (event: MouseEvent) => void;
  groupCount: number;
}

export const WithdrawalMethodGroupList = (props: WithdrawalMethodGroupListProps): null | JSX.Element => {
  const {
    name,
    list,
    handleSelectWithdrawalMethod,
    groupCount,
  } = props;
  const isDesktopLayout = getLayoutType() === LayoutType.desktop;
  const initialTheme = useAppSelector(initialThemeSelector) || getTheme();
  const logoSizes = useAppSelector(logoSizesSelector);
  const { t } = useTranslation();

  if (!list.length) {
    return null;
  }

  const captions = {
    [GROUPS.Popular]: t(`${NAMESPACES.PW_KEYS}:PH.POPULAR_METHOD_LIST_CAPTION`),
    [GROUPS.LostProfit]: t(`${NAMESPACES.PW_KEYS}:PH.LOST_PROFIT_METHOD_LIST_CAPTION`),
    [GROUPS.Regular]: t(`${NAMESPACES.PW_KEYS}:PH.REGULAR_METHOD_LIST_CAPTION`),
    [GROUPS.Disabled]: t(`${NAMESPACES.PW_KEYS}:PH.DISABLED_METHOD_LIST_CAPTION`),
  };

  const methodColumns: UIPayoutGroups[][] = isDesktopLayout
    ? getTwoMethodColumns<UIPayoutGroups>(list)
    : [list];

  if (name === GROUPS.Disabled) {
    const disabledMethods: DisabledMethodsGroups = getDisabledMethods<UIPayoutGroups>(
      list,
      isDesktopLayout,
    );
    const { disabledMethodListUntil, disabledMethodList } = disabledMethods;
    const isDisabledMethodsExist = isDesktopLayout
      ? ((disabledMethodListUntil?.length && disabledMethodListUntil
        .some((item) => Array.isArray(item) && item.length > 0))
          || (disabledMethodList?.length && disabledMethodList
            .some((item) => Array.isArray(item) && item.length > 0))
      )
      : ((disabledMethodListUntil && disabledMethodListUntil[0].length > 0)
          || (disabledMethodList && disabledMethodList[0].length > 0)
      );

    return !isDisabledMethodsExist ? null : (
      <>
        <div
          data-testid="method-group-caption"
          data-translation-key={`${NAMESPACES.PW_KEYS}:PH.DISABLED_METHOD_LIST_CAPTION`}
          data-icon-name="clock_outlined"
          className="method-group-caption"
        >
          <Icon
            className="method-group-caption__icon"
            data-testid="method-group-caption-icon"
            name="clock_outlined"
            size={24}
          />
          <Typography
            variant="caption-1-regular-caps"
            dataTestId="disable-method-list-caption"
          >
            {' '}
            {t(`${NAMESPACES.PW_KEYS}:PH.DISABLED_METHOD_LIST_CAPTION`)}
          </Typography>
        </div>
        <div
          className={clx('withdrawal-methods', 'withdrawal-methods__disabled', {
            desktop: isDesktopLayout,
          })}
          data-testid="method-list-disabled"
        >
          {disabledMethods?.disabledMethodListUntil?.map((column: UIPayoutGroups[], index) => (
            <WithdrawalMethodsListDisabled
              key={`disabledMethodListUntil__withdrawal__${index}`}
              list={column}
              initialTheme={initialTheme}
            />
          ))}
          {disabledMethods?.disabledMethodList?.map((column: UIPayoutGroups[], index) => (
            <WithdrawalMethodsListDisabled
              key={`disabledMethodList__withdrawal__${index}`}
              list={column}
              initialTheme={initialTheme}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      {groupCount !== 1 && (
      <div
        data-testid={`method-group-caption__${name}`}
        data-translation-key={name}
        data-icon-name="clock_outlined"
        className="method-group-caption"
      >
        <Typography
          variant="caption-1-regular-caps"
          dataTestId="group-method-list-caption"
        >
          {' '}
          {captions[name]}
        </Typography>
      </div>
      )}
      <div
        className={clx('withdrawal-methods', {
          desktop: isDesktopLayout,
        })}
        data-testid={`withdrawal-methods-list__${name}`}
      >
        {methodColumns.map((column: UIPayoutGroups[]) => (
          <ListView
            key={JSON.stringify(column)}
            divider
          >
            {column.map((m) => (
              <ListCell
                className="withdrawal-methods__item"
                key={m.id}
                text={getTranslationMessage({
                  t,
                  value: m.displayName,
                }) || ''}
                subtitle={formMethodSubTitle({
                  t,
                  processingTime: m.processingTimeTranslationKey,
                  fee: m.merchantUserFee || {},
                })}
                onClick={handleSelectWithdrawalMethod(m)}
                multiline
                leftImageSize={logoSizes.width}
                image={(
                  <Image
                    width={`${logoSizes.width}`}
                    dataTestId="method-icon"
                    data-id="method-logo"
                    alt={m.displayName}
                    src={updateLogoUrlViaTheme(m.logoUri, initialTheme)}
                    style={{
                      height: logoSizes.height,
                      width: logoSizes.width,
                    }}
                  />
                  )}
                content={<Content icon="chevron-right" />}
              />
            ))}
          </ListView>
        ))}
      </div>
    </>
  );
};
