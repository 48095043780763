import { Experiment, GrowthBook, Result } from '@growthbook/growthbook';

import { FINGERPRINT } from 'store/constants';
import { fetchWithTimeout } from '../../utils/fetchWithTimeout';
import { softLocalStorageGet } from '../../utils';

import { GBFeaturedParam, Exp } from './types';
import { arrStringToNumber } from './utils';

const isDevelopmentMode = process.env.NODE_ENV === 'development';

declare global {
  interface Window {
    growthbookCashier: GrowthBook;
  }
}

const duration = 500;
let loading: boolean | null = null;
let loaded: boolean | null = null;
let failed: boolean | null = null;

const isLoading = () => loading;
const isFailed = () => failed;
const isInitialized = () => loaded && loaded !== null;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const trackingCallback = (exp: Experiment<any>, result: Result<any>) => {
  try {
    if (isDevelopmentMode) {
      // eslint-disable-next-line no-console
      console.log('Experiment Viewed', {
        experiment: exp,
        result,
      });
    }
  } catch { /* empty */ }
};

const growthBook = new GrowthBook({
  trackingCallback,
});

const getChannel = (channel: string) => {
  if (!channel) return '';

  switch (channel) {
    case 'IOS':
      return 'MOBILE_NATIVE_IOS';

    case 'ANDROID':
      return 'MOBILE_NATIVE_ANDROID';

    case 'WEB':
      return 'DESKTOP_AIR_PM';

    default:
      return 'MOBILE_WEB';
  }
};

const initGBFeatures = async (params: GBFeaturedParam) => {
  const {
    currencyAlpha3,
    merchantUrl,
    userId,
    growthbookFeatures,
    brand,
    userBalance,
    clickstreamId,
    language,
    appChannel,
    userAmsSegments,
  } = params;
  const requestUrl = `${merchantUrl}/growthbook/api/features`;
  if (!growthbookFeatures || !currencyAlpha3 || !userId) return;
  const fingerPrint = softLocalStorageGet(FINGERPRINT);

  const attributes = {
    brand,
    currency: currencyAlpha3,
    accountNumber: userId,
    userAgent: navigator.userAgent,
    balanceTotal: userBalance,
    channel: getChannel(appChannel),
    clickstreamId,
    fingerprint: fingerPrint,
    hostName: merchantUrl.replace(/^https?:\/\//i, ''),
    language,
    amsCategories: arrStringToNumber(userAmsSegments),
  };

  growthBook.setAttributes(attributes);

  try {
    loading = true;
    await fetchWithTimeout(requestUrl, duration)
      .then((res) => res.json())
      .then(({ features }: Exp) => {
        const featuredKeys: string[] = Object.keys(features);
        const cashierFeatures = featuredKeys.reduce((acc, key) => {
          if (growthbookFeatures?.includes(key)) {
            const exp = features[key as keyof typeof features] as Omit<Experiment<unknown>, 'key'>;
            acc[key] = exp;
          }
          return acc;
        }, {} as Exp);

        growthBook.setPayload({
          features: cashierFeatures,
        });

        loaded = true;
        loading = false;
        if (!window.growthbookCashier) {
          window.growthbookCashier = growthBook;
        }
      })
      .catch(() => {
        failed = true;
        loading = false;
      });
    // eslint-disable-next-line no-empty
  } catch {}
};

export const getGBFeatures = () => ({
  isFailed,
  isLoading,
  initGBFeatures,
  isInitialized,
  growthBook,
});
