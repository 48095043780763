import { Middleware, configureStore } from '@reduxjs/toolkit';
import card from './slices/userCard';
import common from './slices/global';
import deposit from './slices/deposit';
import checkout from './slices/checkout';
import bonus from './slices/bonus';
import withdrawal from './slices/withdrawal';
import notifications from './slices/notifications';
import errorPopUp from './slices/errorPopUp';
import withdrawalReject from './slices/withdrawalReject';
import accountManagement from './slices/accountManagement';
import contentPage from './slices/contentPage';
import otp from './slices/otp';
import paySessionState from './slices/paySessionState';
import carousel from './slices/carousel';
import submitForm from './slices/submitForm';
import { accountManagementAPI } from './services/accountManagementAPI';
import { depositAPI } from './services/depositAPI';
import { checkoutAPI } from './services/checkoutAPI';
import { contentPageAPI } from './services/contentPageAPI';
import { withdrawalAPI } from './services/withdrawalAPI';
import { withdrawalRejectAPI } from './services/withdrawalRejectAPI';
import { otpAPI } from './services/otpAPI';
import { page3dsAPI } from './services/page3dsAPI';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';
import { listener } from './middlewares/listener';

const middleware: Middleware[] = [
  listener.middleware,
  accountManagementAPI.middleware,
  depositAPI.middleware,
  checkoutAPI.middleware,
  contentPageAPI.middleware,
  withdrawalAPI.middleware,
  withdrawalRejectAPI.middleware,
  otpAPI.middleware,
  page3dsAPI.middleware,
  rtkQueryErrorLogger,
];

export const store = configureStore({
  reducer: {
    card,
    common,
    deposit,
    checkout,
    bonus,
    notifications,
    errorPopUp,
    withdrawal,
    withdrawalReject,
    accountManagement,
    contentPage,
    otp,
    paySessionState,
    carousel,
    submitForm,
    [accountManagementAPI.reducerPath]: accountManagementAPI.reducer,
    [withdrawalAPI.reducerPath]: withdrawalAPI.reducer,
    [depositAPI.reducerPath]: depositAPI.reducer,
    [checkoutAPI.reducerPath]: checkoutAPI.reducer,
    [contentPageAPI.reducerPath]: contentPageAPI.reducer,
    [withdrawalRejectAPI.reducerPath]: withdrawalRejectAPI.reducer,
    [otpAPI.reducerPath]: otpAPI.reducer,
    [page3dsAPI.reducerPath]: page3dsAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // eslint-disable-next-line max-len
    // https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
    serializableCheck: false,
  }).concat(middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
