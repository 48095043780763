import { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form';
import { Button, ListCell, ListView } from '@modulor/react';
import SslSecure from 'assets/SslSecure';
import PciDss from 'assets/PciDss';
import {
  filterFieldsForCheckout,
  getSubmitButtonVariant,
  getThemeByBrand,
  prepareFieldsWithCustomParameters,
} from 'utils';
import {
  Limits,
  MerchantUserFeeResponce,
  Parameter,
  PayHubCashierContractsViewDataResponse,
  PayHubWidgetContractsRecurringCheckResponse,
  PayHubWidgetContractsUserCardResponse,
  ProcessingCurrency,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
} from 'store/models';
import { FlowTypes } from 'store/constants';
import { useAppSelector } from 'store/hooks';
import { bonusDataSelector } from 'store/slices/bonus';
import {
  buildDefaultCheckoutValues,
  getSubmitSubText,
  prepareFieldsDataForSubmit,
  updateFieldsDataStructure,
} from 'Modulor/Deposit/utils';
import { TaxContainer } from 'Modulor/components/Tax/TaxContainer';
import { fieldGetter, getAmountField, getCVVField } from 'Modulor/fieldGetter';
import { cardFieldNameSelector } from 'store/slices/checkout';
import { hasValidationError } from 'utils/form';
import { getCardsIcon, getCardsPlaceholder, getCardsTypeName } from 'Modulor/components/Cards/utils';
import { CvvField } from 'Modulor/components/CvvField';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { getFormattedAmountWithCurrency, getPrettierFormatNumber } from 'utils/dataTransform';
import { brandSelector } from 'store/slices/global';
import { currencyDisplaySelector } from 'store/slices/deposit';
import { getGBFeatures } from 'services/growthbook';
import { NAMESPACES } from 'services/constants';
import { Fee } from '../components/Fee';
import { AmountBlocks } from '../components/AmountBlocks';
import { BonusInfo } from '../components/BonusInfo';
import {
  useDetectNewErrors,
  useFrameChangeHeight,
  useGetTaxInfo,
  useVisualViewportSubmitButton,
} from '../hooks';
import { isShowPCIDSSLogoByBrand, prepareRequiredFields, renderFields } from './utils';
import { SavedCard } from '../components/Cards/SavedCard';
import AdditionalInfo from '../components/AdditionalInfo';
import { selectedCardSelector } from '../../store/slices/userCard';
import { RecurringCheckModal } from './RecurringCheckModal';
import { ConversionBlock } from '../components/ConversionBlock';
import { getAmountBlocksByExperiment } from '../components/utils';

type Inputs = Record<string, string | number | boolean | unknown>;
interface CheckoutFormProps {
  amount: number;
  payGroupId: number;
  methodParameters: Parameter[];
  submitCallback: (fields: Inputs, isNew: boolean) => void;
  cardsList?: PayHubWidgetContractsUserCardResponse[];
  amountBlocks?: number[];
  currency: string;
  amountRegExpFe?: string;
  limits: Limits | null;
  withAmount: boolean;
  isFetchingSubmitRequest: boolean;
  presetCurrency?: string | null;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
  localCurrentIsCVVPresent: boolean;
  recurringCheckTrigger: LazyQueryTrigger<QueryDefinition<{
    cardId: number;
    amount: number;
}, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
never, PayHubWidgetContractsRecurringCheckResponse, 'checkoutAPI'>>;
  shouldVerifyCVV?: boolean;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  methodName: string;
}

const gbFeatures = getGBFeatures();

export const CheckoutForm: FC<CheckoutFormProps> = (props) => {
  const {
    amount,
    submitCallback,
    localCurrentIsCVVPresent,
    methodParameters = [],
    cardsList = [],
    amountBlocks,
    currency,
    amountRegExpFe,
    limits,
    withAmount,
    isFetchingSubmitRequest,
    presetCurrency,
    additionalViewData,
    merchantUserFee,
    fee,
    recurringCheckTrigger,
    shouldVerifyCVV,
    processingCurrency,
    processingCurrencyRate,
    methodName,
    payGroupId,
  } = props;

  const bonusData = useAppSelector(bonusDataSelector);
  const { bonusInfo, bonusByPayGroup, isGeneralBonus } = bonusData || {};
  const { t } = useTranslation();
  const cardInfo = useAppSelector(selectedCardSelector);
  const cardFieldName = useAppSelector(cardFieldNameSelector);
  const currencyDisplaySetting = useAppSelector(currencyDisplaySelector);
  const currencyValue = presetCurrency || currency;
  const [defaultValues, setDefaultValues] = useState(buildDefaultCheckoutValues() as FieldValues);
  const [displayRecurringCheckModal, showRecurringCheckModal] = useState<boolean>(false);
  const gbAmountBlocksResult = gbFeatures.growthBook.evalFeature('billing.recommended.amount');
  const amountBlocksData = getAmountBlocksByExperiment({
    amountBlocks, limits, gbResult: gbAmountBlocksResult?.value,
  });
  const isBonusIncluded = isGeneralBonus || Boolean(bonusByPayGroup?.includes(payGroupId));
  const brand = useAppSelector(brandSelector);
  const showPCIDSSLogo = isShowPCIDSSLogoByBrand(getThemeByBrand(brand));

  const hasAmountBlocks = !!amountBlocksData?.length;
  const amountField = getAmountField({
    amountRegExpFe,
    limits,
    currency: presetCurrency || currency,
    flowType: FlowTypes.deposit,
  });

  const { handleSubmit, control, formState: {
    isValid,
    errors,
    dirtyFields,
  }, setError,
  clearErrors,
  trigger,
  getValues,
  unregister,
  reset,
  setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (cardInfo.selectedCard) {
      const receivedDefaultValues: FieldValues = buildDefaultCheckoutValues(cardInfo.selectedCard, `${amount}`);
      setDefaultValues(receivedDefaultValues);
      reset(receivedDefaultValues);
    }
  }, [cardInfo.selectedCard]);

  const { hasNewErrors } = useDetectNewErrors(errors);

  useFrameChangeHeight(hasNewErrors);

  useEffect(() => {
    if (amount) {
      setValue('amount', amount);
      trigger('amount');
    }
  }, [amount]);

  const submitSubText = useMemo(() => getSubmitSubText({
    t,
    currency: presetCurrency || currency,
    amount,
    bonusInfo,
    processingCurrency,
    processingCurrencyRate,
    currencyDisplaySetting,
  }), [
    currency,
    presetCurrency,
    bonusInfo,
    amount,
    processingCurrency,
    processingCurrencyRate,
  ]);

  const newLocal = fieldGetter({
    t,
    clearErrors,
    setError,
    setValue,
    formField: {
      control,
    },
  })(amountField);

  const { tax, calculateNonTaxableAmount, amountWithTaxes } = useGetTaxInfo(
    amount,
    FlowTypes.deposit,
  );

  const renderedFields = prepareFieldsWithCustomParameters(
    filterFieldsForCheckout(methodParameters, cardInfo),
    localCurrentIsCVVPresent,
    cardInfo,
  );

  const preparedFields = renderFields({
    t,
    clearErrors,
    setError,
    setValue,
    fields: updateFieldsDataStructure(renderedFields, cardInfo),
    control,
  });

  const handleRecurringCheck = (cardId: number) => {
    if (shouldVerifyCVV) {
      showRecurringCheckModal(true);

      return;
    }
    recurringCheckTrigger({
      cardId,
      amount,
    }).then((res) => {
      if (res.data?.is_cvv_required) {
        showRecurringCheckModal(true);
      } else {
        const requiredFields = prepareRequiredFields(methodParameters.filter((field: Parameter) => field.isRequired));
        submitCallback(
          prepareFieldsDataForSubmit(getValues(), cardInfo.selectedCard || {}, requiredFields, dirtyFields),
          !!cardInfo?.selectedCard.isNew,
        );
      }
    });
  };

  const submitHandler: SubmitHandler<Inputs> = (fields: FieldValues) => {
    if (hasValidationError(fields, setError, {
      shouldValidateCard: !!cardInfo.selectedCard.isNew,
      cardFieldName,
      amount,
    })) {
      return;
    }

    const requiredFields = prepareRequiredFields(methodParameters.filter((field: Parameter) => field.isRequired));

    const cvvParam = methodParameters.find((param) => param.extendedType === ExtendedTypes.CardCvv);

    if ((cardInfo.selectedCard?.id && (
      cvvParam && !cvvParam.isRequired && cardInfo.selectedCard?.is_card_token_active))) {
      handleRecurringCheck(cardInfo.selectedCard?.id);
    } else {
      submitCallback(
        prepareFieldsDataForSubmit(fields, cardInfo.selectedCard || {}, requiredFields, dirtyFields),
        !!cardInfo?.selectedCard.isNew,
      );
    }
  };

  const setAmount = (amountFieldValue: number) => setValue('amount', amountFieldValue, {
    shouldValidate: true,
  });

  const amountButtonText = t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.CONTINUE_AMOUNT`, {
    amount: getFormattedAmountWithCurrency(
      getPrettierFormatNumber(amount),
      currencyValue,
      currencyDisplaySetting,
    ),
  });

  const handleCloseRecurringModal = () => {
    unregister('card_cvv');
    showRecurringCheckModal(false);
  };

  const handleConfirmRecurringModal = () => {
    const requiredFields = prepareRequiredFields(methodParameters.filter((field: Parameter) => field.isRequired));
    submitCallback(
      prepareFieldsDataForSubmit(getValues(), cardInfo.selectedCard || {}, requiredFields, dirtyFields),
      !!cardInfo?.selectedCard.isNew,
    );
    unregister('card_cvv');
    showRecurringCheckModal(false);
  };

  const renderRecurringModalBody = () => (
    <div className="cvv-modal-content">
      <ListView className="card-box">
        <ListCell
          text={getCardsTypeName(cardInfo.selectedCard?.bin)}
          subtitle={getCardsPlaceholder(cardInfo.selectedCard?.last4, cardInfo.selectedCard?.bin)}
          image={getCardsIcon(cardInfo.selectedCard?.bin)}
        />
      </ListView>
      <CvvField
        {...getCVVField()}
        control={control}
        tooltipPlacement="left"
      />
    </div>
  );

  const hasNotErrors = !Object.keys(errors).length;
  const { formStyles } = useVisualViewportSubmitButton(!isValid || !hasNotErrors);

  if (!renderedFields?.length) {
    return null;
  }

  return (
    <form
      className="checkout-form"
      onSubmit={handleSubmit(submitHandler)}
    >
      { bonusInfo && isBonusIncluded && (
      <BonusInfo {...bonusInfo} />
      )}
      {!!cardsList?.length && (
        <SavedCard
          cardsState={cardInfo}
          cardsList={cardsList}
        />
      )}
      <div className="checkout-form__content">
        {withAmount && newLocal}
        {hasAmountBlocks && (
          <AmountBlocks
            currentAmount={amount}
            amountBlocks={amountBlocksData}
            onClick={setAmount}
            currency={currency}
          />
        )}
        <Fee
          fee={fee}
          merchantUserFee={merchantUserFee}
        />
        <TaxContainer
          tax={tax}
          isTaxHintShown
          calculateNonTaxableAmount={calculateNonTaxableAmount}
          taxAmount={amountWithTaxes.taxAmount}
          amount={amount}
        />
        <ConversionBlock
          amount={amount}
          methodName={methodName}
          processingCurrency={processingCurrency}
          processingCurrencyRate={processingCurrencyRate}
        />
        {preparedFields}
        {showPCIDSSLogo
            && (
            <div className="checkout-form__bottom-icons-box">
              <SslSecure />
              <PciDss />
            </div>
            )}
      </div>
      <AdditionalInfo additionalData={additionalViewData} />
      <div className="checkout-form__footer">
        <Button
          type="submit"
          disabled={!isValid || !hasNotErrors}
          loading={isFetchingSubmitRequest}
          variant={getSubmitButtonVariant(hasNotErrors)}
          doubleText={submitSubText}
          style={formStyles}
        >
          {amountButtonText}
        </Button>
      </div>
      <RecurringCheckModal
        isOpened={displayRecurringCheckModal}
        bodyContent={renderRecurringModalBody()}
        onClose={handleCloseRecurringModal}
        onConfirm={handleConfirmRecurringModal}
      />
    </form>
  );
};
