import React, { FC, lazy, useEffect } from 'react';
import { ListView, ListCell } from '@modulor/react';
import { FlowTypes } from 'store/constants';
import {
  Clickstream,
  getClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import { getStringWithoutSpaces } from 'utils';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';

const BonusInfoIcon = lazy(() => import('./BonusInfoIcon'));

interface BonusInfoTopBlock {
  bonusName?: string;
  bonusDescription?: string;
}

export const BonusInfo: FC<BonusInfoTopBlock> = (props) => {
  const {
    bonusName,
    bonusDescription,
  } = props;
  const { t } = useTranslation();
  const clickstream = Clickstream.use();
  const cashierContext = getClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  // temporary we can get  bonusName & bonusDescription with the same text and should avoid duplicating
  // https://gr8-tech.atlassian.net/browse/BILL-16749
  const isParamsEquals = getStringWithoutSpaces(bonusName || '') === getStringWithoutSpaces(bonusDescription || '');
  const transformedBonusName = (bonusName && !isParamsEquals) ? bonusName : t(`${NAMESPACES.PW_KEYS}:PH.BONUS`);

  useEffect(() => {
    const event = getClickStreamEvent.cashier_method_bonus_banner_view;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
  }, []);

  const bonusInfoCLickHandler = () => {
    const event = getClickStreamEvent.cashier_method_bonus_banner_click;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
  };

  return (
    <ListView dataTestId="bonus-info-component">
      <ListCell
        image={<BonusInfoIcon />}
        text={bonusDescription}
        subtitle={transformedBonusName}
        multiline
        inverted
        onClick={bonusInfoCLickHandler}
      />
    </ListView>
  );
};
