/* eslint-disable @typescript-eslint/no-empty-function */
import { Control, FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { PhoneField } from '@modulor/react';
import {
  CustomRegExp,
  Limits,
  Option,
  Parameter,
  PayHubAdminPanelDomainEnumsParameterDataType as DataType,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType as Type,
} from 'store/models';
import { FlowTypes } from 'store/constants';
import { FieldType, getTranslationMessage } from 'utils';
import { CvvField } from './components/CvvField';
import { AmountField } from './components/AmountField';
import { CheckboxField } from './components/CheckboxField';
import { ExpirationDateField } from './components/ExpirationDateField';
import { SelectField } from './components/SelectField';
import { InputField } from './components/TextField';
import { CardField } from './components/CardField';
import { findCountryDetailsByPhoneNumber } from './OTP/utils';
import { BetShopsField } from './Withdrawal/BetShops/BetShopsField';
import { CustomPhoneField } from './components/PhoneField/PhoneField';
import { DateInputField } from './components/DateField/DateInputField';
import { TFunction } from '../commonTypes';

interface FormField {
  control: Control;
}

export interface FieldConfig extends Parameter {
  limits?: Limits | null;
  dataType: DataType;
  customRegExp?: CustomRegExp;
  items?: Parameter[];
  isDisabled?: boolean;
  currency?: string;
  flowType?: FlowTypes;
}

interface GetFieldTypeProps {
  name: string;
  type: string;
  dataType: DataType;
  options?: Option[];
  extendedType?: string;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
const getFieldType = (props: GetFieldTypeProps): FieldType => {
  const {
    name,
    type,
    dataType,
    options,
    extendedType,
  } = props;

  if (type === 'select' && extendedType === ExtendedTypes.BetShop) {
    return FieldType.betShop;
  }

  if (name === 'amount') {
    return FieldType.amount;
  }

  if (dataType === DataType.Boolean || type === 'check') {
    return FieldType.checkbox;
  }

  if (name === 'expirationDate') {
    return FieldType.expirationDate;
  }

  if (type === 'select' && options?.length) {
    return FieldType.select;
  }

  if ([
    `${ExtendedTypes.CardPan}`,
    `${ExtendedTypes.CreditCard}`,
  ].includes(`${extendedType}`)) {
    return FieldType.cardPan;
  }

  if (type === 'select' && !options?.length) {
    return FieldType.input;
  }

  if (type === 'user_phone_visible') {
    return FieldType.userPhoneVisible;
  }

  if (extendedType === ExtendedTypes.CardCvv) {
    return FieldType.cardCvv;
  }

  if (type === 'input' && extendedType === ExtendedTypes.Phone) {
    return FieldType.phone;
  }

  if (type === 'date') {
    return FieldType.date;
  }

  return FieldType.input;
};

interface FieldGetterProps {
  setError: UseFormSetError<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  formField: FormField;
  isCheckout?: boolean;
  t: TFunction;
}

// eslint-disable-next-line react/display-name
export const fieldGetter = (props: FieldGetterProps) => (
  fieldConfig: FieldConfig,
) => {
  const {
    setError,
    setValue,
    clearErrors,
    formField,
    isCheckout,
    t,
  } = props;
  const { control } = formField;
  const {
    name,
    type,
    options = [],
    key,
    label,
    dataType,
    isRequired,
    limits,
    regexp = undefined,
    extendedType,
    customRegExp,
    placeHolder = '',
    isDisabled = false,
    currency = '',
    flowType = FlowTypes.deposit,
    userRequisite = '',
    verifyNewPayoutAccount,
    validationMsgKey,
    validationKeys,
  } = fieldConfig;

  const {
    min = 0,
    max = undefined,
  } = limits || {};
  const fieldType = getFieldType({
    name,
    type,
    dataType,
    options,
    extendedType,
  });

  const labelText = getTranslationMessage({
    t,
    value: label || name,
  });
  const placeholderText = getTranslationMessage({
    t,
    value: placeHolder,
  });

  switch (fieldType) {
    case FieldType.amount: {
      return (
        <AmountField
          key={key}
          name={name}
          label={labelText}
          placeHolder={placeholderText}
          control={control}
          min={min}
          max={max}
          currency={currency}
          flowType={flowType}
          regexp={regexp}
          isDisabled={isDisabled}
        />
      );
    }

    case FieldType.checkbox: {
      return (
        <CheckboxField
          key={key}
          name={name}
          control={control}
          isRequired={isRequired}
          isCheckout={Boolean(isCheckout)}
          label={labelText}
          placeHolder={placeholderText}
        />
      );
    }

    case FieldType.expirationDate: {
      return (
        <ExpirationDateField
          key={key}
          name={name}
          control={control}
          customRegExp={customRegExp}
          validationKeys={validationKeys}
        />
      );
    }

    case FieldType.betShop: {
      return (
        <BetShopsField
          key={key}
          name={name}
          setValue={setValue}
        />
      );
    }

    case FieldType.select: {
      return (
        <SelectField
          key={key}
          name={name}
          label={labelText}
          placeHolder={placeholderText}
          isRequired={isRequired}
          control={control}
          setValue={setValue}
          options={options}
        />
      );
    }

    case FieldType.cardPan: {
      return (
        <CardField
          key={key}
          name={name}
          label={labelText}
          placeHolder={placeholderText}
          control={control}
          regexp={regexp}
          isRequired={isRequired}
          type={type}
          extendedType={extendedType}
          setError={setError}
          clearErrors={clearErrors}
          verifyNewPayoutAccount={verifyNewPayoutAccount}
          validationMsgKey={validationMsgKey}
        />
      );
    }

    case FieldType.cardCvv: {
      return (
        <CvvField
          key={key}
          name={name}
          label={label}
          placeHolder={placeHolder}
          control={control}
          regexp={regexp}
          isRequired={isRequired}
          extendedType={extendedType}
          validationMsgKey={validationMsgKey}
        />
      );
    }

    case FieldType.userPhoneVisible: {
      const phoneValue = userRequisite || '';
      const countryDetails = findCountryDetailsByPhoneNumber(phoneValue as string);
      const phoneCode = countryDetails?.phonecode;
      const countryCode = countryDetails?.iso;
      const phone = phoneCode ? phoneValue.replace(`+${phoneCode}`, '') : phoneValue;

      return (
        <PhoneField
          key={key}
          name={name}
          disabled={isDisabled}
          value={phone}
          label={placeholderText}
          countryCode={countryCode}
          isVisibleFlagArrow
          onChange={() => {}}
        />
      );
    }

    case FieldType.phone: {
      return (
        <CustomPhoneField
          name={name}
          label={label}
          isRequired={isRequired}
          isDisabled={isDisabled}
          validationMsgKey={validationMsgKey}
          control={control}
          defaultValue={userRequisite}
        />
      );
    }

    case FieldType.date: {
      return (
        <DateInputField
          key={key}
          name={name}
          label={label}
          control={control}
          isRequired={isRequired}
          placeHolder={placeHolder}
          isDisabled={isDisabled}
          defaultValue={userRequisite}
          validationMsgKey={validationMsgKey}
        />
      );
    }

    default: {
      return (
        <InputField
          key={key}
          name={name}
          label={labelText}
          placeHolder={placeholderText}
          control={control}
          regexp={regexp}
          isRequired={isRequired}
          extendedType={extendedType}
          isCheckout={Boolean(isCheckout)}
          isDisabled={isDisabled}
          defaultValue={userRequisite}
          validationMsgKey={validationMsgKey}
        />
      );
    }
  }
};

interface GetAmountFieldProps {
  amountRegExpFe?: string;
  limits: Limits | null;
  currency: string;
  flowType: FlowTypes;
  isDisabled?: boolean;
}
export const getAmountField = (props: GetAmountFieldProps): FieldConfig => ({
  name: 'amount',
  key: 'amount',
  regexp: props.amountRegExpFe,
  type: Type.Input,
  dataType: DataType.Float,
  isRequired: true,
  limits: props.limits,
  currency: props.currency,
  flowType: props.flowType,
  isDisabled: props.isDisabled,
});

export const getCardField = (props: Parameter): FieldConfig => ({
  ...props,
  options: undefined,
  type: Type.Input,
  dataType: DataType.String,
  isRequired: true,
});

export const getCVVField = (): Parameter => ({
  key: 'card_cvv',
  name: 'card_cvv',
  type: 'input',
  extendedType: ExtendedTypes.CardCvv,
  dataType: DataType.String,
  regexp: '',
  label: 'PH.LABEL.CVV',
  isRequired: false,
});
